@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --black: #000000;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  height: 100%;
  background-color: white;
  overflow-x: visible;
}

html {
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: visible;
  max-width: 100%;
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
  scroll-padding: 10rem;
}

#root {
  width: 100%;
  height: 100%;
}

p, h1, label, a, h1 {
  font-family: GraphikRegular;
}

@font-face {
  font-family: GraphikRegular;
  src: url("assets/fonts/GraphikRegular.otf");
}

@font-face {
  font-family: "Gruppo";
  src: url("assets/fonts/body-text.ttf");
}
@font-face {
  font-family: "Monument";
  src: url("assets/fonts/subtitles-45pt.otf");
}
@font-face {
  font-family: "Monument-bold";
  src: url("assets/fonts/Title\ font-100pt.otf");
}